import React from 'react';
import Img from 'gatsby-image';

import { Organizer } from '../../../types/organizer';

import styles from './eventOrganizers.module.scss';

interface DataProps {
  organizers?: Organizer[];
}
export const EventOrganizers: React.FC<DataProps> = ({ organizers }) => {
  if (!organizers) return null;

  return (
    <div className={styles['organizers-wrapper']}>
      <h4 className={styles['organizers-wrapper__title']}>Georganiseerd door</h4>
      <ul className={styles['organizers-list']}>
        {organizers.map((organizer: Organizer, index: number) => (
          <OrganizerItem key={index} organizer={organizer} />
        ))}
      </ul>
    </div>
  );
};

interface OrganizersDataProps {
  organizer: Organizer;
}
const OrganizerItem: React.FC<OrganizersDataProps> = ({ organizer }) => {
  if (!organizer) return null;

  const { frontmatter } = organizer;
  if (!frontmatter) {
    return null;
  }
  return (
    <li className={styles['organizer-listitem']}>
      {frontmatter.logo && <Img className={styles['logo']} fluid={frontmatter.logo.childImageSharp.fluid} />}
      {/* {frontmatter.title} */}
    </li>
  );
};
