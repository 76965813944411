import React from 'react';
import { graphql, PageProps, Link } from 'gatsby';
import { Layout } from '../../components/shared/Layout';
import { SEO } from '../../components/shared/seo';
import Img from 'gatsby-image';

import styles from './eventTemplate.module.scss';
import { ImageSharpFluid } from '../../helpers/definitions';
import { EventDisciplines } from '../../components/event/EventDisciplines';
import { EventActivities } from '../../components/event/EventActivities';
import { EventVenues } from '../../components/event/EventVenues';
import { EventContact } from '../../components/event/EventContact';
import { EventOrganizers } from '../../components/event/EventOrganizers/eventOrganizers';
import Share from '../../components/shared/Share/share';
import { IOrganizer } from '../../types/organizer';
import { IActivity } from '../../types/activity';
import { IVenue } from '../../types/venue';
import { EventMap } from '../../components/event/EventMap';

interface DataProps {
  site: {
    siteMetadata: {
      url: string;
      twitterHandle: string;
    };
  };
  event: {
    frontmatter: {
      title: string;
      subtitle: string;
      publish_date: string;
      categories: string[];
      disciplines: string[];
      dates: string[];
      activities?: IActivity[];
      organizers?: IOrganizer[];
      venues?: IVenue[];
      contact: string;
      cover: {
        childImageSharp: {
          fluid: ImageSharpFluid;
        };
      };
      booking_details?: string[];
    };
    html: string;
  };
}

const EventTemplate: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { event, site } = data;
  const { frontmatter, html } = event;
  const sc = {
    twitterHandle: site.siteMetadata.twitterHandle,
    config: { url: site.siteMetadata.url, title: frontmatter.title },
  };
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className="container">
        <header className={styles['event-header']}>
          <div className="row">
            <div className="col-xs-6">
              <Link to="/programma" className={styles['backlink']}>
                &larr; Bekijk het volledige programma
              </Link>
            </div>
            <div className="col-xs-6" style={{ textAlign: 'right' }}>
              <Share socialConfig={sc} />
            </div>
          </div>

          {frontmatter.categories && (
            <div className={styles['categories']}>
              {frontmatter.categories.map((cat: any, index: number) => (
                <span key={index} className={styles['category']}>
                  {cat}
                </span>
              ))}
            </div>
          )}
          <h1 className={styles['event-title']}>{frontmatter.title}</h1>

          {frontmatter.cover && <Img fluid={frontmatter.cover.childImageSharp.fluid} />}
        </header>

        <section className={styles['event-main']}>
          <div className="row">
            <div className="col-xs-12 col-md-8">
              {frontmatter.subtitle && <h2 className={styles['event-subtitle']}>{frontmatter.subtitle}</h2>}
              <div className={styles['event-content']} dangerouslySetInnerHTML={{ __html: html }} />
            </div>
            <div className="col-xs-12 col-md-4">
              <aside>
                <EventDisciplines items={frontmatter.disciplines} />
                <EventActivities bookingDetails={frontmatter.booking_details} activities={frontmatter.activities} />
                <EventVenues venues={frontmatter.venues} />
                {frontmatter.contact && <EventContact contact={frontmatter.contact} />}
                <EventOrganizers organizers={frontmatter.organizers} />
              </aside>
            </div>
          </div>
        </section>
      </div>

      {frontmatter.venues && <EventMap venues={frontmatter.venues} />}
    </Layout>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        url
        twitterHandle
      }
    }
    event: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        booking_details
        publish_date(formatString: "MMMM DD, YYYY")
        slug
        title
        subtitle
        categories
        disciplines
        activities {
          id
          frontmatter {
            activity_type
            title
            description
            date_start(formatString: "YYYY-MM-DD H:mm")
            date_end(formatString: "YYYY-MM-DD H:mm")
            price
            booking_text
            booking_type
            booking_link
            booking_required
          }
        }
        venues {
          frontmatter {
            title
            street
            postal
            city
            lat
            lng
            gmaps
            website
            instagram
            twitter

            logo {
              childImageSharp {
                fluid(maxWidth: 1200, maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        organizers {
          frontmatter {
            title
            logo {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        contact
        cover {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default EventTemplate;
