import React from 'react';
import moment from 'moment';

import styles from './eventActivities.module.scss';
import { IActivity } from '../../../types/activity';
import { FaInfoCircle } from 'react-icons/fa';

interface EventActivitiesProps {
  activities?: IActivity[];
  bookingDetails?: string[];
}
export const EventActivities: React.FC<EventActivitiesProps> = ({ activities, bookingDetails }) => {
  if (!activities && !bookingDetails) return null;

  return (
    <div className={styles['activities-wrapper']}>
      <h3 className={styles['activities__title']}>Activiteiten</h3>
      {bookingDetails && (
        <ul className={styles['booking-details']}>
          {bookingDetails.map((item: string, index: number) => (
            <li key={index} className={styles['booking-details__item']}>
              <FaInfoCircle /> {item}
            </li>
          ))}
        </ul>
      )}
      <ul className={styles['activities-list']}>
        {activities &&
          activities.map((activity: IActivity, index: number) => {
            if (!activity) return;
            return (
              <li key={index} className={styles['activity-listitem']}>
                {activity.type && <div className={styles['activity-type']}>{activity.type}</div>}

                <h5 className={styles['activity__date']}>
                  {moment(activity.date_start, 'YYYY-MM-DD H:mm').format('dddd H:mm')} -{' '}
                  {moment(activity.date_end, 'YYYY-MM-DD H:mm').format('H:mm')}
                </h5>
                <h4 className={styles['activity-title']}>{activity.title}</h4>
                <div className={styles['activity-description']}>{activity.description}</div>
                {/* Booking details */}
                <div className={styles['booking-details']}>
                  {/* <h5 className={styles['booking-title']}>Registratie details</h5> */}
                  {activity.booking_required && (
                    <p className={styles['booking-required']}>
                      Registratie vereist
                      {activity.booking_type && (
                        <span className={styles['booking-type']}>via {activity.booking_type}</span>
                      )}
                    </p>
                  )}
                  {activity.booking_text && <p className={styles['booking-text']}>{activity.booking_text}</p>}
                  {activity.booking_link && (
                    <a className={styles['booking-link']} href={activity.booking_link}>
                      Registreren voor deze {activity.type} &rarr;
                    </a>
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
