import React from 'react';
import Img from 'gatsby-image';

import styles from './eventVenues.module.scss';
import { IVenue } from '../../../types/venue';
import { FaGlobe, FaPhone, FaInstagram, FaTwitter } from 'react-icons/fa';

interface DataProps {
  venues?: IVenue[];
}
export const EventVenues: React.FC<DataProps> = ({ venues }) => {
  if (!venues) return null;

  return (
    <div className={styles['venues-wrapper']}>
      <h4 className={styles['venues__title']}>Locaties(s)</h4>
      <ul className={styles['venues-list']}>
        {venues.map((venue: IVenue, index: number) => (
          <li key={index} className={styles['venue-listitem']}>
            <VenueItem venue={venue} />
          </li>
        ))}
      </ul>
    </div>
  );
};

interface VenueItemProps {
  venue: IVenue;
}
const VenueItem: React.FC<VenueItemProps> = ({ venue }) => {
  const { instagram, twitter, logo, title, street, postal, city, telephone, website } = venue.frontmatter;
  return (
    <div className={styles['venue-details']}>
      {logo && <Img fluid={logo.childImageSharp.fluid} />}
      <strong>{title}</strong>
      <br />
      {street} <br />
      {postal} {city}
      <br />
      {telephone && (
        <div>
          <FaPhone />
          {telephone}
        </div>
      )}
      <div className={styles['socials']}>
        {website && (
          <a className={styles['socials__item']} href={website} target="_blank">
            <FaGlobe />
          </a>
        )}
        {instagram && (
          <a className={styles['socials__item']} href={`https://instagram.com/${instagram}`} target="_blank">
            <FaInstagram />
          </a>
        )}
        {twitter && (
          <a className={styles['socials__item']} href={`https://twitter.com/${twitter}`} target="_blank">
            <FaTwitter />
          </a>
        )}
      </div>
    </div>
  );
};
