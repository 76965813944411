import React from 'react';

import styles from './eventContact.module.scss';

interface DataProps {
  contact: string;
}
export const EventContact: React.FC<DataProps> = ({ contact }) => {
  if (!contact) return null;

  return (
    <div className={styles['contact-wrapper']}>
      <h4 className={styles['contact-title']}>Contact</h4>
      <div className={styles['contact-content']}>{contact}</div>
    </div>
  );
};
