import React from 'react';

import styles from './eventDisciplines.module.scss';

interface DataProps {
  items?: string[];
}
export const EventDisciplines: React.FC<DataProps> = ({ items }) => {
  if (!items) return null;

  return (
    <div className={styles['discipline-wrapper']}>
      <h3 className={styles['discipline-wrapper__title']}>Disciplines</h3>
      {items.map((item: string, index: number) => (
        <span key={index} className={styles['discipline']}>
          {item}
        </span>
      ))}
    </div>
  );
};
